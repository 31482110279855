.Modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.477);

  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: 10;
  
}

.Modal_EditProduct {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.068);

  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: 10;
  
}
  
  
.Modal__container {
  background-color: white;
  border-radius: 1rem;
  
  padding: 1rem;
  padding-bottom: 2rem;
  margin: auto;
  
  width: 85vw;
  max-width: 62rem;

  height: auto;
  max-height: 85vh;
}
  
.Modal__close-button {
  border: 0;
  border-radius: 1rem;
  background-color: white;
  
  padding: 1rem 1rem;
  margin: 0;
  font-size: 18px;
}

.Modal__close-button:hover {
  background-color: black;
  color: white;
}
