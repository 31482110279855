
.chat-wa {
  
    border-radius: 15px;
    width: 20rem;
    padding: 0.5rem;

    display: flex;
    justify-content: space-between;
    color: white;
    background-color: #25d366;

    position: fixed;
    bottom: 3rem;
    right: 0.5rem;
}
.chat-wa:hover {
    background-color: #128c7e;
}

.chat-wa p {
    margin: 0;
}

.chat-wa__icon p {
    font-size: 3rem;
    text-align: center;
}