.payment-container-desktop {
    width: 100%;
    height: 90vh;
    display: grid;
    grid-template-columns: 50% 50%;
    
}
.payment-data {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
}

.payment-container-desktop .summary {
    display: flex;
  
    align-items: center;
    flex-direction: column;
    background-color: rgb(241, 241, 241);
    overflow-y: scroll;
  }
  

.payment-container-tablet-mobile {
    width: 100%;
    height: 90vh;
    
    overflow-y: scroll;

    display: flex;
    
    align-items: center;
    flex-direction: column;
    
}

.payment-container-tablet-mobile 
.headers-payment-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.payment-container-tablet-mobile .summary-mobile {
    width: 90%;
}

.payment-container-tablet-mobile .payment {
    width: 90%;
}

.payment 
.select-payment-method {
    width: 90%;
}