
.checkout-steps {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 4rem auto;
  }
  .checkout-steps > div {
    border-top: 0.35rem #c0c0c0 solid;
    color: #c0c0c0;
    flex: 1 1;
    padding-top: 1rem;
  }
  .checkout-steps > div.active {
    border-top: 0.35rem var(--blue2) solid;
    color: var(--blue2);
  }