.form-searcher {
    
    width: 100%;
    max-width: 45rem;
}

@media screen and (max-width: 770px) {
    .form-searcher {
    
        max-width: 90rem;
    }
}

@media screen and (min-width: 1100px) {
    .form-searcher {
    
        max-width: 80rem;
    }
}

@media screen and (min-width: 1249px) {
    .form-searcher {
    
        max-width: 95rem;
    }
}

.form-searcher form {
    background-color: whitesmoke;
    width: 100%;
    
    display: flex;
    justify-content: center;
    border: solid rgb(170, 170, 170) 1px;
    border-radius: 5px;
}



.search-input {
    border: none;
    border-radius: 0%;
    background-color: whitesmoke;
    height: 5rem;
    width: 80%;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    font-size: 1.75rem;
    
}


.search-button {
    background-color: whitesmoke;
    border-left: 0.1rem solid;
    border-right: none;
    border-top: none;
    border-bottom: none;
    border-color: rgb(187, 187, 187);
    height: 5rem;
    width: 17%;
    margin: 0;
}
