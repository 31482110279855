

/* user Dropdown styles */

.dropdown-user {
    border: solid 1px;
    position: absolute;
    top: 80px;
    right: 0px;
    transform: translateY(50px);
    border-radius: 2px;
    width: 200px;
    background-color: whitesmoke;
    z-index: 30;
  }

  
  
.user-dropdown-animation-enter {
    opacity: 0;
    
    transform: scale(0.9);
  }
  .user-dropdown-animation-enter-active {
    opacity: 1;
    transform: translateY(50px);
    transition: all 500ms;
  }
  .user-dropdown-animation-exit {
    opacity: 1;
  }
  .user-dropdown-animation-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: all 500ms;
  }

/************************************************************************************************/

  .dropdown-category {
    /* border: solid 1px;
    position: absolute;
    top: 800px;
    right: 0px;
    transform: translateY(50px);
    width: 200px;
    background-color: whitesmoke;
    z-index: 30; */
    border-radius: 2px;
    border: black;
    z-index: 30;
  }




/************************************************************************************************/

  
  .user-dropdown-item {
    padding: 5px;
    font-size: 1.5rem;
    margin: 0;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    display: block;
    background-color: whitesmoke;
    color: black;
    
    border-radius: 2px;
  }

  button.user-dropdown-item {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  
  .user-dropdown-item:hover {
    background-color: var(--blue1);
    color: white;
  }
  