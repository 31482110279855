.recommended-products {
  display: flex;
  flex-direction: column; 
  align-items: center;
  width: 100%;
  
}

.recommended-products-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 150rem;
}

.recommended-products-header h1{
  text-align: center;
  margin-top: 6rem;
}


ul.products-recommended {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;

  padding-bottom: 2rem;
  padding-top: 2rem;
}

ul.products-recommended li {
  /* flex: 0 1 34rem; */
  padding: 0;
  margin: 1rem;
  margin-bottom: 3rem;
} 