.offer-form {
    margin: 3rem;
    max-width: 90rem;
}
.display-search {
    height: 45rem;
    overflow-y: scroll;
    
}

.display-search table {
    width: 100%;
}

.display-search img, .display-offer-prods img{
    width: 3rem;
}

.display-offer-prods .form-list .offer-prices-list{
    
    height: 30rem;
    overflow-y: scroll;
    width: 90rem;
}

.display-offer-prods table {
    width: 100%;
}