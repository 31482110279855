.signupUser-container, .signupAdmin-container, .changePassword-container{
    padding: 5rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul.signup-form a{
    display: inline-block;
    text-decoration: underline;
}

li.checkbox-li{
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 0;
}


input.tyc-checkbox:hover {
    cursor: pointer;
}