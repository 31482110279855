html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
}

body {
  /* font: 1.6rem Helvetica; */
  height: 100vh;
  margin: 0;
  font-size: 1.6rem;
  font-family: Lato, sans-serif;
}

#root {
  height: 100%;
}

:root {
  --blue1: #5dc5f1;
  --blue2: #43a6ad;
  --blue3: #203040;
  /* --gray: #9e9c9c; */
  --gray: #807e7e;
  --red: #dc514a;
  --green: #1eb379;
}

/* .grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  
  grid-template-rows: 8rem 1fr auto;
  height: 100%;
}

.header {
  grid-area: header;
}

.main {
  grid-area: main;
}

.footer {
  grid-area: footer;
} */



/* ------------  Styles defined --------------------------- */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  margin-bottom: 1rem;
  outline: none;
}

button.primary1,
a.primary1 {
  /* margin: 0; */
  background-color: var(--blue1);
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  color: white;
  border: solid var(--blue1) 2px;
  border-radius: 4px;
}

button.primary2,
a.primary2 {
  /* margin: 0; */
  background-color: var(--blue2);
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  color: white;
  border: solid var(--blue2) 2px;
  border-radius: 4px;
}

button.basic,
a.basic {
  /* margin: 0; */
  background-color: var(--gray);
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  color: white;
  border: solid var(--gray) 2px;
  border-radius: 4px;
}

button.delete,
a.delete {
  /* margin: 0; */
  background-color: var(--red);
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  color: white;
  border: solid var(--red) 2px;
  border-radius: 4px;
}

.inline {
  display: inline-block;
}

button:hover {
  cursor: pointer;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 56rem;
  padding: 0 3rem;
}

form.form {
  width: 100%;
}

.form-list label {
  margin: 0 !important ;
}

.form-list li {
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.form-list input {
  height: 3rem;
  padding: 0.5rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
}

.form-list select {
  height: 4rem;
  padding: 0.5rem;
  border: 0.1rem #c0c0c0 solid;
  background-color: white;
  border-radius: 0.5rem;
}

.error-text {
  color: var(--red);
}

.clip-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  color: var(--red);
  margin: 0;
}

.win-message {
  color: var(--green);
  margin: 0;
}

/* Scrollbar styles */

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--blue2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #43a6ad8e;
}

/* Table styles */

table {
  border-collapse: collapse;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table tr {
  height: 5rem;
}

table select {
  font-size: 1.5rem;
  padding: 7px;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--blue2);
  color: white;
}

table button {
  width: 14rem;
}
