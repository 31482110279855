.menu-list {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 5rem;
   
}
.menu-list li {
    width: 10rem;
    height: 3rem;

    margin: 0 2rem;
    padding: 0;
    border: none
}

.categories-menu-container {
    background-color: whitesmoke;
    color: black;
    
}

li .category-button{
    /* padding: 2rem 2.5rem; */
    border: black 2px;
    color: black;
    display: block;
    height: 100%;
    width: 100%;
    line-height: 3rem;
    text-align: center;
    font-size: 1.8rem;
    border: none;
    
    
}

li .category-button:hover {
    border-bottom: solid var(--blue2) 3px;
    color: rgb(66, 66, 66);
}


