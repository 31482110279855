.customer-relations {
    display: flex;
    flex-direction: column;
}

.customer-offerings {
    display: flex;
    justify-content: center;
}

.customer-offerings ul{
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
}

.customer-offerings li {
    border-bottom: solid var(--blue2);
    font-size: 2rem;
    display: flex;
    height: 10rem;
}

.inline-container {
    display: inline-block;
}

.icon_cr {
    padding: 2rem;
    font-size: 4.5rem;
    width: 5rem;
    color: var(--blue2);
}

.text_cr{
    padding: 0.5rem;
    width: 25rem;
    width: 100%;
}

.leyenda {
    display: flex;
    text-align: center;
    
    align-self: center;
    max-width: 900px;
}