.footer {
  --background: whitesmoke;
  --fontColor:  var(--gray);
}

.footer {
  background-color: var(--background);
  color: var(--fontColor);
}

.footer-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* height: 250px; */
}

.footer-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-subgrid {
  display: grid;
  grid-template-columns: auto auto;
  
}

.contact  img {
  width: 20rem;
}

.contact-items, .contact-email-tel  {
  margin-left: 1.3rem;
}

.contact-items li {
  margin: 1.2rem 0rem;
  display: inline-block;
  color: var(--fontColor);;
}


.contact-email-tel li {
  margin: 1.2rem 0rem;
  color: var(--fontColor);;
}

.contact-items, .contact-email-tel .icon {
  font-size: 3rem;
  margin-right: 2rem;
  color: var(--fontColor);;
}

ul.contact-items a {
  text-decoration: none;
  font-size: 3rem;
  margin-right: 2rem;
  color: var(--fontColor);
}

.contact-items .address a {
  color: var(--fontColor);;
}



.policys ul li {
  margin: 1.2rem 0rem;
  color: var(--fontColor);

}
.policys a{
  text-decoration: none;
  color: var(--fontColor);
}

.payment-methods ul li {
  margin: 1.2rem 0rem;
}

.payment-methods ul {
  display: flex;
  
}
.payment-icon {
  font-size: 5rem;
  margin-right: 3rem;
}

.rigths-reserved h4 {
  text-align: center;
}
