
.product_Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 27rem;
    
    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 3rem;
}


.product_Info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.2rem;
    height: 16rem;
}
  
.product_Info  .name-container {
    height: 6.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.product_Info  p.status {
    margin-top: 1rem;
}

.name-container .name{
    margin: 0;
}
.product_Info .action {
    display: flex;
    justify-content: space-between;
}

.product_Info .action .price {
    font-size: 2rem;
    font-weight: bold;
}
.product_Info .status{
    margin-top: 0;
}

.product_Info .action .add-to-cart .qty-select {
    margin-right: 1rem;
    padding: 0.4rem 0.5rem;
    border-radius: 5px;
    background-color: whitesmoke;
}

  
.product_Info .action .add-to-cart button {
    border-style: none;
    background-color: var(--blue1);
    border-radius: 5px;
    color: white;
    margin: 0;
    padding: 0.5rem 2rem;
    cursor: pointer;
}

.product_Info .action .add-to-cart button:hover {
    
    background-color: rgb(19, 49, 107);
    
}