.sidebar{
    position: fixed;
    top: 0;
    transform: translateX(-33rem);
    width: 33rem;
    background-color: var(--blue2);
    color: white;
    transition: all 0.3s;
    height: 100%;
    overflow-y: scroll;
    z-index: 2;
}

.sidebar.open{
    transform: translateX(0);
    transition: all 0.3s;
  }
  
.sidebar-close-button{
    color: white;
    border: none;
    width: 5rem;
    height: 5rem;
    padding: 2rem;
    font-size: 3rem;
    cursor: pointer;
    position: absolute;
    right: 2rem;
    top: 0rem
}

.sidebar ul li{
    height: 5rem;
}

.sidebar ul li a {
    color: white;
    display: block;
    line-height: 5rem;
    text-align: center;
    font-size: 1.9rem;
}

.sidebar ul li button{
    color: white;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0;
    
    
    font-size: 1.9rem;
    
    
    padding: 0px 100px;
}


.sidebar ul li button:hover, 
.sidebar ul li button:active,
.sidebar ul li a:hover, 
.sidebar ul li a:active {
    background-color: #235e6286;
}

.sidebar h3 {
    margin-left: 2rem;
}
.user-icon-sidebar {
    margin-right: 1.5rem;
}