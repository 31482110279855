.verificationEmail_page_container {
    height: 60vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    padding: 5rem;
    
}

.verificationEmail_content_container {
    border: rgba(194, 193, 193, 0.524) solid 2px;
    border-radius: 5px;

    width: 100%;
    max-width: 70rem;
    padding: 3rem;
}

.verificationEmail_page_container .message {
    text-align: center;
}

.verificationEmail_content_container .action{
    display: flex;
    flex-direction: column;
}