
.sidebar-filters{
    display: none;
    position: absolute;
    top: 0;
    /* transform: translateX(-600rem); */
    width: 70%;
    background-color: var(--blue2);
    color: white;
    transition: all 0.3s;
    height: 100%;
    z-index: 3;
    overflow-y: scroll;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
}

.sidebar-filters.open{
    /* transform: translateX(0); */
    display: block;
    
    left: 0;
    transition: all 0.3s;
  }
  
.sidebar-filters-close-button{
    color: white;
    width: 5rem;
    height: 5rem;
    padding: .5rem;
    font-size: 3rem;
    padding-top: 0;
    cursor: pointer;
    position: absolute;
    left: .5rem;
    top: 1.5rem
}

.sidebar-filters .ddf-header {
  color: white ;
  border-color: white;
}

