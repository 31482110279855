
.cart-page-container{
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container_Cart {
    width: 100%;
    max-width: 130rem;

    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
}


.cart-legend-container {
    width: 100%;
    max-width: 110rem;
}


.products-list_Cart  ul {
    list-style: none;
    
}

.cart-action_Cart-grid_cointaner {
    display: flex;
    justify-content: flex-end;
}

.cart-action_Cart {
    display: flex;
    flex-direction: column;
    width: 60%;

    max-height: 30rem;

    background-color: white;
    border: solid var(--blue3) 0.5px;
    border-radius: 1rem;
    padding: 0rem 2rem;
    margin-bottom: 3rem;
}




@media screen and (max-width: 930px) {
    .container_Cart {
        display: flex;
        flex-direction: column-reverse;
        /* justify-content: center; */
    }
    .cart-action_Cart, .products-list_Cart {
      width: 100%;
    }

    .cart-action_Cart-grid_cointaner {
        display: flex;
        justify-content: flex-end;
    }

    
}

@media screen and (max-width: 500px) {
    .products-list_Cart  ul li .cart-item_Card{
        display: flex;
        flex-direction: column;
    
        text-align: center;
    }
    
}

