.shipping-headers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3rem;
}

.shipping-headers h3,
.shipping-headers h2 {
    margin: 0.5;
}

