

  


.carousel-item {
    height: 40rem;
    color: white;
}

.carousel-item h2{
    margin: 0;
    text-align: center;

    /* To center the h2 */
    position: relative;
    top: 30%;
}

.carousel-item.offer {
    color: rgb(0, 0, 0);
}

.carousel-item.envio{
    background-color: rgb(228, 226, 226);
    color: var(--red);
}

button.link-to-offer {
    width: 100%;
    height: 100%;
    
}

button.link-to-offer:hover {
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .carousel-item {
        font-size: 3rem;
    }
}

@media screen and (max-width: 1024px) {
    .carousel-item {
        font-size: 1.5rem;
    }
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}