.dropdown-category {
    
    border: black solid 1px !important;
    border-radius: 2px;
    padding: 3px;
    width: 200px;
    
    background-color: whitesmoke;
    
    position: relative;
    right: 100%;
    /* top: 80px;
    right: 0px;
    transform: translateY(50px); */
    z-index: 30;
    

}

.category-dropdown-item {
    padding: 5px;
    font-size: 1.5rem;
    margin: 0;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    display: block;
    background-color: whitesmoke;
    color: black;
    
    border-radius: 2px;
}

button.category-dropdown-item {
    width: 100%;
    height: 100%;
    margin: 0;
}
  
.category-dropdown-item:hover {
    background-color: var(--blue1);
    color: white;
}
  