
.shipping-container-desktop {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
  
}

.shipping-container-desktop h5{
  margin: 3px;
  
}



.shipping-container-desktop .shipping-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;

  width: 100%;
}


.shipping-form-container {
  width: 90%;
  max-width: 90rem !important;
}

.shipping-form-container .form-container{
  width: 90%;
  max-width: 90rem !important;
}

.shipping-form-container button.storepickup{
  width: 100%;
  max-width: 90rem !important;
}

.shipping-container-desktop .summary {
  display: flex;

  align-items: center;
  flex-direction: column;
  background-color: rgb(241, 241, 241);
  overflow-y: scroll;
}



.shipping-container-tablet-mobile {

  width: 100%;
  
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
  
}

.shipping-container-tablet-mobile
.headers_shipping-container{
  display: flex;
  flex-direction: column;
  width: 90%;
}

.shipping-container-tablet-mobile 
.shipping-data{
  width: 90%;
}

.shipping-container-tablet-mobile 
.shipping-form-container {
  width: 100%;
}

.shipping-container-tablet-mobile 
.form ul.form-list {
  width: 100%;
}


.summary-mobile {
  display: flex;
  flex-direction: column;

  width: 90%;
  
  background-color: rgb(255, 255, 255) !important;
  
  font-size: 1.5rem;

  transform: translateY(-50rem);
  opacity: 0;
  display: none;
  transition: all 0.05s;
  height: 0;
}



.summary-mobile div {
  width: 100%;
}

.summary-mobile.open {
  transform: translateY(0rem);
  height: auto;
  opacity: 1;
  display: inline-block;
  transition: all 0.4s;
}