.not-found-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.not-found-container img {
    max-width: 80rem;
    display: block;
    margin-top: 10rem;
    margin-left: auto;
    margin-right: auto;
}