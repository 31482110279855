
  
  .shipping-data button.storepickup {
    width: 100%;
    max-width: 56rem;
    margin-bottom: 10rem;
  }
  
  .shipping-data .resend_email {
    width: 100%;
    max-width: 56rem;
  }
  
  .shipping-data .resend_email button {
    width: 100%;
    
  }



 


  button.show-details-button {
    width: 100%;
    padding: 2rem 0.5rem;
    text-align: start;

    border-bottom: solid rgb(141, 141, 141) 1px;

    display: flex;
    justify-content: space-between;
      
  }
