.dashboard-page{
    display: grid;
    grid-template-columns: 2fr 8fr;
}

@media only screen and (max-width: 768px){
    .dashboard-page{
        display: grid;
        grid-template-columns: 1fr;
    }
}


.menu-dashboard {
    background-color: whitesmoke;
    height: 100%;
}
.menu-dashboard li{
    height: auto;    
    line-height: 6rem;
    padding: 1rem 0rem;
    width: 100%;
}

.dashboard-link {
    display: block;
    padding-left: 3rem;
    font-size: 2rem;
    color: black;
}

.dashboard-link:hover{
    background-color: var(--blue2);
    color: white;
}

.dashboard-link:visited{
    background-color: var(--blue2);
    
}
.page-dashboard {
    height: 100vh;
    padding-left: 3rem;
    padding-top: 3rem;
    margin-bottom: 3rem;
    overflow-y: scroll;
}