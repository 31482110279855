.shippingDetails-item_Card {
    display: flex;
    
    
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-between;
}

.shippingDetails-item_Image img {
    width: 5rem;
}

.shippingDetails-item_Image  {
    display: flex;
    align-items: center;
    justify-content: center;   
}


.shippingDetails-item_Info {
    padding-left: 3rem;
}

.shippingDetails-item_Price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 3rem;
}