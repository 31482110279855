 
    
.pagination li{
    display: inline-block;
    border: solid var(--blue2) 1px;
    margin: 0rem 0.5rem;
    border-radius: 4px;
    padding: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    
} 


.pagination li button{
    margin: 0;
    width: 100%;
    display: block;
    line-height: 3rem;
    padding: 0.75rem 1rem;
    min-width: 3.5rem;
    border-radius: 4px;
    
    
} 

ul.pagination li.page-item.active button.page-link {
    color: #ffffff;
    background-color: var(--blue2);
    border: none; 
}




ul.pagination button.page-link {
   
    text-align: center;
    color: var(--blue2);
    font-weight: 900;
}

ul.pagination button.page-link:hover {
    background-color: var(--blue2); 
    color: white;
} 