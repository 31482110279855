.select-payment-method {
    display: flex;
    
    flex-direction: column;
    width: 70%;
    max-width: 56rem;
    padding: 2rem;
    border: .1rem  #c0c0c0 solid;
    border-radius: 0.5rem;
    
}

.select-payment-method label {
    font-size: 2rem;
    margin-left: 1rem;
}

.select-payment-method .paypal {
    padding-top: 2.5rem 0rem;
}

.select-payment-method .card {
    padding: 2.5rem 0rem;
}

.billing button{
    width: 100%;
}


#form-checkout input{
    height: 3rem;
    padding: 0.5rem;
    border: 0.1rem #c0c0c0 solid;
    border-radius: .5rem;
    margin-bottom: 2rem;
    display: inline-block;
    width: 97%;
}

#form-checkout label{
    display: block;
    text-align: start;
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
}

.form .form-group {
    display: flex;
    flex-direction: row;
}


.card-number-field{
    width: 100%;
}

.security-code {
    width: 48%;
    display: inline-block;
}

.expiration-date {
    width: 48%;
}

.expiration-date-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.expiration-date input{
    width: 40% !important;
}


.expiration-and-cvv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


#form-checkout button {
    width: 100%;
}


