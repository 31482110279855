
.results-page-grid-container {
  
  display: flex;
  justify-content: space-evenly;
  
}

.filters-desktop-grid-item {
    
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;
    
    max-width: 200px;
}

.results-params-grid-item {
  

  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-left: 5rem;
  /* overflow-y: scroll; */
  /* height: 85vh; */
}

@media screen and (max-width: 768px) {
  /* .results-page-grid-container {
    display: grid;
    grid-template-columns: auto;
  } */

  .filters-desktop-grid-item {
    display: none;
  }

  .results-params-grid-item {
  
    /* grid-column-start: 1;
    grid-column-end: 2;
     */
  
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }  

  ul.products{
    display: flex;
    align-items: center;
    justify-content: center !important;
  }
  
}





