.modal-item{
    /* font-size: 10px; */
}

@media only screen and (max-width: 500px) {
    .modal-item {
        font-size: 10px;
    }
}

.modal-item_Card {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* justify-content: space-between; */
    border-top: black 0.2rem solid;
    border-bottom: black 0.2rem solid;
    
}

.modal-item_Image {
    text-align: center;
    
}

.modal-item_Image img {
    height: 35vh;
    
}

.modal-item_Info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal-item_Info h4 {
    margin: 0;
}

.add-to-cart-modal-action {
    display: flex;
    justify-content: flex-end;
}

.add-to-cart-modal-action {
    margin-top: 2rem;
}
.add-to-cart-modal-action button {
    margin: 0 1rem;
    width: 22rem;    
    font-size: 15px; 

}


.modal-transition-enter {
    opacity: 0;
    transform: scale(0.8);
}

.modal-transition-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 500ms;
}

.modal-transition-exit {
    opacity: 1;
}

.modal-transition-exit-active {
    opacity: 0;
    transform: scale(0.8);
    transition: all 500ms;
}