.cart-item_Card {
    display: flex;
    
    border-bottom: black 0.2rem solid;
    margin-bottom: 3rem;
}

.cart-item_Image img {
    width: 20rem;
}


.cart-item_Info button.delete{
    width: 10rem;
    
}

.cart-item_Info select {
    width: 10rem;
    padding: 1rem;
    font-size: 1.7rem;
    margin-right: 2rem;
}

.delete-transition-enter {
    opacity: 0;
}

.delete-transition-enter-active {
    opacity: 1;
    transition: all 500ms;
}

.delete-transition-exit {
    opacity: 1;
}

.delete-transition-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: all 500ms;
}