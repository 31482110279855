.filters-button {
    border: solid var(--blue2) 1px;
    background-color: white;
    border-radius: 0.5rem;
    font-size: 2.5rem;
    padding: 1rem;
    color: var(--blue2);
    --color-filter-icon: #43a6ad;
}
.filters-button:hover, .filters-button:active  {
    background-color: var(--blue2);
    color: white;
    --color-filter-icon: white;

}

.filters-button-container {
    margin-top: 3rem;
}

.filters-icon{
    fill: var(--color-filter-icon);
    width: 20px; 
    height: 20px;
}