.payment-message-page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
    align-items: center;
    text-align: center;
    height: 80vh;
}

.payment-message-page a{
    width: 80%;
    max-width: 50rem;
    text-align: center;
    font-size: 2.5rem;
}
