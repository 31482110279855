.tyc-page{
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-wrapper ul{
    width: 100%;
    list-style: disc;
    max-width: 95%;
    /* padding-left: 5rem;
    padding-right: 5rem; */
}

.text-wrapper{
    max-width: 70rem;
    text-align: justify;
}

.text-wrapper p{
    margin-bottom: 3rem;
}

.text-wrapper .list-wrapper{
    width: 90%;
    padding-left: 4rem;
}