.shippingDetails_items{
    width: 80%;
    margin-top: 5rem;
}

.shippingDetails_discountCode {
    border-bottom: rgb(209, 209, 209) 0.1rem solid;    
    border-top: rgb(209, 209, 209) 0.1rem solid;
    width: 80%;
}

.shippingDetails_subtotal{
    border-bottom: rgb(209, 209, 209) 0.1rem solid;
    width: 80%;
}

.shippingDetails_subtotal  p{
    display: inline-block;
}

.shippingDetails_subtotal .subtotal, .shippingDetails_subtotal .discount-code, .shippingDetails_total {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}



.shippingDetails_total{
    width: 80%;
}

.shippingDetails_discountCode .form-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.input-discount-code{
    width: 80%;
    height: 6rem;
}


.button-discount-code{
    width: 20%
}

.shippingDetails_discountCode .form-list li input{
    height: 6rem ;
    font-size: 2rem;
}

.shippingDetails_discountCode .form-list li button{
    height: 6rem;
    font-size: 2rem;
}

.form-list {

}