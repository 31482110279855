.ddf-header {
    color: var(--blue2);
    width: 100%;
    display: flex;
    justify-content: space-between;

    border-bottom: solid var(--blue2) 0.1rem;
}

.ddf-header:hover {
    cursor: pointer;
}

.ddf-header__title {
    
    width: 90%;
}
.ddf-header h2{
    margin: 1.5rem 0rem;
    font-weight: 500;
}

.ddf-wrapper, .ddf-content {
    margin: 2rem 0rem;
}