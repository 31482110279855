
header.header {
  /* position: sticky;
  position: -webkit-sticky;
  top: 0;  */
}

.navbar {
  background-color: whitesmoke;
  color: #000000;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  
  z-index: 20;
}

/* The sticky class is added to the navbar with JS 
when it reaches its scroll position */
.scrolled {
  /* position: fixed;
  top: 0;
  width: 100%; */
  /* justify-content: center; */
}

.hamburger-menu {
  font-size: 4rem;
  color: var(--blue1);
}

.brand a {
  color: #090909;
  font-size: 2.5rem;
  font-weight: bold;
  text-decoration: none;
}

.brand img {
  max-height: 7rem;
}



.navbar-items {
  /* border-style: solid; */
  height: 100%;
  width: 90px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-items li {
  height: 48px;
  display: flex;

  /* border-style: solid; */
}

.cart-counter-badge {
  
  position: relative;
  top: -6.3rem;
  right: 1.8rem;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: var(--blue2);
  color: white;
  font-size: 1.5rem; 
  
}

.icon-button {
  font-size: 3.5rem;
  padding: 10%;
  color: var(--blue1);
}
