
.slideShow_DotsContainer {
  text-align: center;
  padding: 10px;
}

.slideShow_dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .slideShow_dot:hover {
  background-color: #717171;
}



/* Next & previous buttons */
.slide_prev, .slide_next {
  cursor: pointer;
  
  margin-top: -22px;
  padding: 16px;

  position: absolute;
  top: 50rem;
  width: auto;
  
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 18px;
  
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.slide_next {
  right: 0;
  border-radius: 3px 0 0 3px;
}